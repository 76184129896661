var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"exercise-wrapper page",attrs:{"id":"wrapperdiv"}},[_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-start",attrs:{"cols":"3"}},[_c('span',{staticClass:"data-text ml-3"},[_vm._v(_vm._s(_vm.$t("Name"))+":")]),_c('span',{staticClass:"data-text ml-1"},[_vm._v(_vm._s(_vm.userData.name))])]),_c('b-col',{staticClass:"d-flex justify-content-start",attrs:{"cols":"3"}},[_c('span',{staticClass:"data-text"},[_vm._v(_vm._s(_vm.$t("Age"))+":")]),_c('span',{staticClass:"data-text ml-1"},[_vm._v(_vm._s(_vm.userData.age))])]),_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"3"}},[_c('span',{staticClass:"data-text"},[_vm._v(_vm._s(_vm.$t("Start"))+":")]),_c('span',{staticClass:"data-text ml-1"},[_vm._v(_vm._s(new Date(_vm.userData.program.startDate).toLocaleDateString(_vm.$i18n.locale)))])]),_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"3"}},[_c('span',{staticClass:"data-text"},[_vm._v(_vm._s(_vm.$t("Progress"))+":")]),_c('span',{staticClass:"data-text ml-1 mr-1"},[_vm._v("("+_vm._s(Math.ceil(_vm.userData.program.exercisesDone / 12))+" / "+_vm._s(_vm.userData.program.exercisesTotal / 12)+")")])])],1),_c('b-row',{staticStyle:{"margin-top":"-45px"}},[_c('b-col',{attrs:{"cols":"12"}},[_c('vue-apex-charts',{key:_vm.refreshKey,attrs:{"height":"220","width":"100%","options":Object.assign({}, _vm.lineChartModifiedOptions,
          {title: {
            text: _vm.$t('Attention') + ' ' + _vm.$t('Performance'),
            offsetY: 25,
            align: 'center',
            style: {
              color: '#000',
            },
          }}),"series":[_vm.attentionChartData]}})],1)],1),_c('b-row',{staticStyle:{"margin-top":"-45px"}},[_c('b-col',{attrs:{"cols":"12"}},[_c('vue-apex-charts',{key:_vm.refreshKey,attrs:{"height":"220","width":"100%","options":Object.assign({}, _vm.lineChartModifiedOptions,
          {title: {
            text: _vm.$t('Logic') + ' ' + _vm.$t('Performance'),
            offsetY: 25,
            align: 'center',
            style: {
              color: '#000',
            },
          }}),"series":[_vm.memoryChartData]}})],1)],1),_c('b-row',{staticStyle:{"margin-top":"-45px"}},[_c('b-col',{attrs:{"cols":"12"}},[_c('vue-apex-charts',{key:_vm.refreshKey,attrs:{"height":"220","width":"100%","options":Object.assign({}, _vm.lineChartModifiedOptions,
          {title: {
            text: _vm.$t('Concentration') + ' ' + _vm.$t('Performance'),
            offsetY: 25,
            align: 'center',
            style: {
              color: '#000',
            },
          }}),"series":[_vm.concentrationChartData]}})],1)],1),_c('b-row',{staticStyle:{"margin-top":"-45px"}},[_c('b-col',{attrs:{"cols":"12"}},[_c('vue-apex-charts',{key:_vm.refreshKey,attrs:{"height":"220","width":"100%","options":Object.assign({}, _vm.lineChartModifiedOptions,
          {title: {
            text: _vm.$t('Memory') + ' ' + _vm.$t('Performance'),
            offsetY: 25,
            align: 'center',
            style: {
              color: '#000',
            },
          }}),"series":[_vm.memoryChartData]}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }