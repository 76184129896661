import i18n from "@/libs/i18n";

export const smallChartOptions = {
	chart: {
		type: "line",
		stacked: true,
		toolbar: {
			show: false,
			tools: {
				download: false,
				selection: false,
				zoom: false,
				zoomin: false,
				zoomout: false,
				pan: false,
				reset: false,
			},
		},
	},
	tooltip: {
		enabled: false,
		x: { show: false, enabled: false },
		y: { show: false, enabled: false },
	},
	colors: ["#000", "#000"],
	dataLabels: {
		enabled: true,
		textAnchor: "middle",
		formatter: function(val, { seriesIndex, dataPointIndex, w }) {
			if (val == -1) val = "Ø";
			if (seriesIndex == 1)
				return `${val > 5 ? "5+" : val}${val >= 0
					? i18n.t("Minute").slice(0, 1).toUpperCase()
					: ""}`;
			else
				return `${val}${val >= 0
					? i18n.t("Mistake").slice(0, 1).toUpperCase()
					: ""}`;
		},
		background: {
			enabled: true,
			foreColor: "#fff",

			padding: 4, // borderRadius: "",
			borderWidth: 1,
			borderColor: "#fff",
			opacity: 1,
		},
		style: { fontSize: "10px" },
	}, // dataLabels: {
	// 	enabled: true,
	// 	textAnchor: "end",
	// 	formatter: function(val, { seriesIndex, dataPointIndex, w }) {
	// 		// formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
	// 		// 	return `${i18n.t("Set")} ${value}`;
	// 		// },
	// 		if (!val) return "";
	// 		// console.log(val);
	// 		const numberToGrade = ["0", "E", "D", "C", "B", "A"];
	// 		let group = val % 1 * 10;
	// 		if (group == 0) return val;
	// 		else return Math.floor(val) + " " + numberToGrade[Math.round(group)];
	// 	},
	// 	background: {
	// 		enabled: true,
	// 		foreColor: "#fff",
	// 		padding: 4,
	// 		// borderRadius: "",
	// 		borderWidth: 1,
	// 		borderColor: "#fff",
	// 		opacity: 1,
	// 	},
	// 	style: {
	// 		fontSize: "10px",
	// 	},
	// },
	stroke: { curve: "smooth", width: 0 },
	grid: {
		borderColor: "#000",
		strokeDashArray: 0,
		xaxis: { lines: { show: true, color: "#000" } },
		yaxis: { lines: { show: false } },
	}, // }, // 	return Math.floor(value); // formatter: function(value, { series, seriesIndex, dataPointIndex, w }) { // xaxis: { // 	title: { text: i18n.t("Sets"), style: { color: '#000' } }, // 	labels: { show: true, style: { colors: '#000' } }, // dropShadow: { enabled: true, left: 2, top: 2, opacity: 0.5 },
	// },
	xaxis: {
		labels: { show: true },
	},
	yaxis: {
		min: 0,
		max: 11,
		tickAmount: 1,
		position: "back",
		labels: {
			show: false, // offsetX: -20,
			// min: 0,
			// title: {
			// 	text: i18n.t("Sets"),
			// },
			style: { colors: "#000" },
		},
	},
	legend: { enabled: false, show: false },
};
// title: {
// 	text: i18n.t("Score"),
// 	rotate: -90,
// },
// annotations: {
// 	position: "back",
// 	yaxis: [
// 		{ y: 0, borderColor: "#000000", borderWidth: 2, strokeDashArray: 0 },
// 		{ y: 100, borderColor: "#000000", borderWidth: 2, strokeDashArray: 0 },
// 	],
// },
// 	formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
// 		return Math.floor(value);
// 	}, // 		borderWidth: 2, //
