<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title class="mb-1">
          {{ $t("Session Progress") }}
        </b-card-title>
        <b-card-sub-title>{{
          $t("Client session progress by critera")
        }}</b-card-sub-title>
      </div>
    </b-card-header>
    <!-- chart -->
    <b-card-body>
      <chartjs-component-line-chart
        :height="400"
        :data="chartjsData.lineChart.data"
        :options="chartOptions"
        :plugins="plugins"
        :key="rerenderkey"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BCardSubTitle, BCardTitle } from "bootstrap-vue";
import ChartjsComponentLineChart from "./charts-components/ChartjsComponentLineChart.vue";
import chartjsData from "./chartjsData";
import axios from "@axios";
const {
  axiosRoutes: { exercise: path },
} = require("/G_CONFIG");
export default {
  emits: ["dataFetchComplete"],
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    ChartjsComponentLineChart,
  },
  data() {
    return {
      chartjsData,
      chartOptions: {
        ...chartjsData.lineChart.options,
        dataLabels: {
          enabled: true,
          dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.5,
          },
        },
        // theme: { monochrome: { enabled: false } },
        // yaxis: { max: 8 },
        // xaxis: {
        //   labels: {
        //     formatter: function (val) {
        //       return val.toFixed(0);
        //     },
        //   },
        // },
        // markers: { size: 5, hover: { size: 9 } },
        // legend: {
        //   labels: { colors: [$themeColors.primary, $themeColors.info] },
        //   itemMargin: { horizontal: 5 },
        //   position: "top",
        //   show: true,
        //   onItemHover: { highlightDataSeries: true },
        //   onItemClick: { toggleDataSeries: true },
        // },
      },

      rerenderkey: 0,
      plugins: [
        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.afterFit = function () {
              this.height += 20;
            };
            /* eslint-enable */
          },
        },
      ],
    };
  },
  async mounted() {
    const id = window.location.pathname.substring(
      window.location.pathname.lastIndexOf("/") + 1
    );
    try {
      //       :data="chartjsData.lineChart.data"
      // :options="chartjsData.lineChart.options"
      // lineChart.data.labels > labels
      // linechart.data.datasets > [
      //           {
      //     data: [10, 20, 20, 30, 35, 40],
      //     label: "Dikkat",
      //     borderColor: chartColors.lineChartDanger,
      //     lineTension: 0.5,
      //     pointStyle: "circle",
      //     backgroundColor: chartColors.lineChartDanger,
      //     fill: false,
      //     pointRadius: 1,
      //     pointHoverRadius: 5,
      //     pointHoverBorderWidth: 5,
      //     pointBorderColor: "transparent",
      //     pointHoverBorderColor: $themeColors.white,
      //     pointHoverBackgroundColor: chartColors.lineChartDanger,
      //     pointShadowOffsetX: 1,
      //     pointShadowOffsetY: 1,
      //     pointShadowBlur: 5,
      //     pointShadowColor: chartColors.tooltipShadow,
      //   },
      // ]
      const { data: response } = await axios.get(`${path.stats + id}`);
      console.dir(response);
      console.dir(chartjsData.lineChart.data);
      // console.dir(this.chartjsData.lineChart.data);
      chartjsData.lineChart.data.labels = response.labels;
      // this.chartjsData.linechart.data.datasets = [];
      // this.chartjsData.lineChart.data.labels = push();
      chartjsData.lineChart.data.datasets[0].data = response.bigChartData[0].data;
      chartjsData.lineChart.data.datasets[0].label = response.bigChartData[0].label;
      chartjsData.lineChart.data.datasets[1].data = response.bigChartData[1].data;
      chartjsData.lineChart.data.datasets[1].label = response.bigChartData[1].label;
      chartjsData.lineChart.data.datasets[2].data = response.bigChartData[2].data;
      chartjsData.lineChart.data.datasets[2].label = response.bigChartData[2].label;
      chartjsData.lineChart.data.datasets[3].data = response.bigChartData[3].data;
      chartjsData.lineChart.data.datasets[3].label = response.bigChartData[3].label;
      // console.dir(this.chartjsData);
      console.dir(chartjsData.lineChart.data);
      this.rerenderkey++;
      this.$emit("dataFetchComplete", response);
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
