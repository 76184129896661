export const lineChartOptions = {
	chart: {
		type: "line",
		toolbar: {
			show: false,
			tools: {
				download: false,
				selection: false,
				zoom: false,
				zoomin: false,
				zoomout: false,
				pan: false,
				reset: false,
			},
		},
		animations: {
			enabled: false,
		},
	},
	tooltip: {
		enabled: false,
		x: { show: false, enabled: false },
		y: { show: false, enabled: false },
	},
	colors: ["#000"],
	dataLabels: {
		enabled: true,
		textAnchor: "end",
		formatter: function(val, { seriesIndex, dataPointIndex, w }) {
			// formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
			// 	return `${i18n.t("Set")} ${value}`;
			// },
			if (!val) return "";
			// console.log(val);
			const numberToGrade = ["0", "E", "D", "C", "B", "A"];
			let group = val % 1 * 10;
			if (group == 0) return val;
			else return Math.floor(val) + " " + numberToGrade[Math.round(group)];
		},
		background: {
			enabled: true,
			foreColor: "#FFF",
			padding: 4,
			// borderRadius: "",
			// backgroundColor: "#fff",
			borderWidth: 1,
			borderColor: "#FFF",
			opacity: 1,
		},
		style: {
			fontSize: "10px",
		},
	},
	stroke: { curve: "smooth", width: 2 },
	grid: {
		borderColor: "#000",
		strokeDashArray: 10,
		yaxis: { lines: { show: true, color: "#000" } },
	}, // 	return Math.floor(value); // formatter: function(value, { series, seriesIndex, dataPointIndex, w }) { // xaxis: { // 	title: { text: i18n.t("Sets"), style: { color: '#000' } }, // 	labels: { show: true, style: { colors: '#000' } }, // dropShadow: { enabled: true, left: 2, top: 2, opacity: 0.5 },
	// },
	// },
	xaxis: {
		// min: 0,
		// title: {
		// 	text: i18n.t("Sets"),
		// },
	},
	yaxis: {
		min: -20,
		max: 120,
		tickAmount: 14,
		position: "back",
		labels: {
			show: true,
			offsetX: -20,
			style: {
				colors: "#000",
			},
			formatter: function(val) {
				return parseInt(val);
			},
		},
		// title: {
		// 	text: i18n.t("Score"),
		// 	rotate: -90,
		// },
	},
	legend: {
		enabled: false,
	},
	annotations: {
		position: "back",
		yaxis: [
			{ y: 0, borderColor: "#000000", borderWidth: 2, strokeDashArray: 0 },
			{ y: 100, borderColor: "#000000", borderWidth: 2, strokeDashArray: 0 },
		],
	},
};
// 	formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
// 		return Math.floor(value);
// 	}, // 		borderWidth: 2, //
