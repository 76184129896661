<template>
  <div class="exercise-wrapper page" id="wrapperdiv">
    <!-- Info Row -->
    <b-row>
      <b-col cols="3" class="d-flex justify-content-start">
        <span class="data-text ml-3">{{ $t("Name") }}:</span>
        <span class="data-text ml-1">{{ userData.name }}</span>
        <!-- <span class="data-text">{{ $t("Attention") }}</span> -->
      </b-col>
      <!-- <b-col cols="1">
        <span class="data-text">:</span>
        <span class="data-text">:</span>
      </b-col> -->
      <!-- <b-col cols="2"> -->
      <!-- <span class="data-text">{{ numberToGrade(userData.program.groups.attention.group)
          }}</span> -->
      <!-- </b-col> -->
      <b-col cols="3" class="d-flex justify-content-start">
        <span class="data-text">{{ $t("Age") }}:</span>
        <span class="data-text ml-1">{{ userData.age }}</span>
        <!-- <span class="data-text">{{ $t("Logic") }}</span> -->
      </b-col>
      <!-- <b-col cols="1">
        <span class="data-text">:</span>
        <span class="data-text">:</span>
      </b-col> -->
      <!-- <b-col cols="2"> -->
      <!-- <span class="data-text">{{ numberToGrade(userData.program.groups.logic.group) 
          }}</span> -->
      <!-- </b-col> -->
      <b-col cols="3" class="d-flex justify-content-end">
        <span class="data-text">{{ $t("Start") }}:</span>
        <span class="data-text ml-1">{{ new Date(userData.program.startDate).toLocaleDateString($i18n.locale)
          }}</span>
        <!-- <span class="data-text">{{ $t("Concentration") }}</span> -->
      </b-col>
      <!-- <b-col cols="1">
        <span class="data-text">:</span>
        <span class="data-text">:</span>
      </b-col> -->
      <!-- <b-col cols="2"> -->
      <!-- <span class="data-text">{{ numberToGrade(userData.program.groups.concentration.group)
          }}</span> -->
      <!-- </b-col> -->
      <b-col cols="3" class="d-flex justify-content-end">
        <span class="data-text">{{ $t("Progress") }}:</span>
        <span class="data-text ml-1 mr-1"><!-- {{ (
                (userData.program.exercisesDone / userData.program.exercisesTotal) *
                100
              ).toFixed(2)
            }}%  -->({{ Math.ceil(userData.program.exercisesDone / 12) }} /
          {{ userData.program.exercisesTotal / 12 }})</span>
        <!-- <span class="data-text">{{ $t("Memory") }}</span> -->
      </b-col>
      <!-- <b-col cols="1">
        <span class="data-text">:</span>
        <span class="data-text">:</span>
      </b-col> -->
      <!-- <b-col cols="2"> -->
      <!-- <span class="data-text">{{ numberToGrade(userData.program.groups.memory.group)
          }}</span> -->
      <!-- </b-col> -->
    </b-row>
    <!-- Groups Row -->
    <!-- <b-col cols="3">
        <b-row class="match-height pl-5 pr-5">
          <span class="data-text">{{ $t("Attention") }}</span>
          <span class="data-text">:</span>
          <span class="data-text">
            {{ numberToGrade(userData.program.groups.attention.group) }}
          </span>
        </b-row>
      </b-col>
      <b-col cols="3">
        <b-row class="match-height pl-5 pr-5">
          <span class="data-text">{{ $t("Logic") }}</span>
          <span class="data-text">:</span>
          <span class="data-text">{{ numberToGrade(userData.program.groups.logic.group) }}</span>
        </b-row>
      </b-col>
      <b-col cols="3">
        <b-row class="match-height pl-5 pr-5">
          <span class="data-text">{{ $t("Concentration") }}</span>
          <span class="data-text">:</span>
          <span class="data-text">
            {{ numberToGrade(userData.program.groups.concentration.group) }}
          </span>
        </b-row>
      </b-col>
      <b-col cols="3">
        <b-row class="match-height pl-5 pr-5">
          <span class="data-text">{{ $t("Memory") }}</span>
          <span class="data-text">:</span>
          <span class="data-text">{{ numberToGrade(userData.program.groups.memory.group) }}</span>
        </b-row>
      </b-col>
    </b-row> -->
    <!-- Attention Chart Row-->
    <b-row style="margin-top: -45px">
      <b-col cols="12">
        <!-- <b-button disabled style="float: right" variant="info" class="mr-5">{{ $t("Comparison Report")
              }}</b-button>
            <b-button disabled style="float: right" variant="primary" class="mr-5">{{ $t("Subject Report")
              }}</b-button> -->
        <vue-apex-charts height="220" width="100%" :options="{
            ...lineChartModifiedOptions,
            title: {
              text: $t('Attention') + ' ' + $t('Performance'),
              offsetY: 25,
              align: 'center',
              style: {
                color: '#000',
              },
            },
          }" :series="[attentionChartData]" :key="refreshKey" />
      </b-col>
    </b-row>
    <!-- Logic Chart Row-->
    <b-row style="margin-top: -45px">
      <b-col cols="12">
        <!-- <b-button disabled style="float: right" variant="info" class="mr-5">{{ $t("Comparison Report")
              }}</b-button>
            <b-button disabled style="float: right" variant="primary" class="mr-5">{{ $t("Subject Report")
              }}</b-button> -->
        <vue-apex-charts height="220" width="100%" :options="{
            ...lineChartModifiedOptions,
            title: {
              text: $t('Logic') + ' ' + $t('Performance'),
              offsetY: 25,
              align: 'center',
              style: {
                color: '#000',
              },
            },
          }" :series="[memoryChartData]" :key="refreshKey" />
      </b-col>
    </b-row>
    <!-- Concentration Chart Row-->
    <b-row style="margin-top: -45px">
      <b-col cols="12">
        <!-- <b-button disabled style="float: right" variant="info" class="mr-5">{{ $t("Comparison Report")
              }}</b-button>
            <b-button disabled style="float: right" variant="primary" class="mr-5">{{ $t("Subject Report")
              }}</b-button> -->
        <vue-apex-charts height="220" width="100%" :options="{
            ...lineChartModifiedOptions,
            title: {
              text: $t('Concentration') + ' ' + $t('Performance'),
              offsetY: 25,
              align: 'center',
              style: {
                color: '#000',
              },
            },
          }" :series="[concentrationChartData]" :key="refreshKey" />
      </b-col>
    </b-row>
    <!-- Memory Chart Row-->
    <b-row style="margin-top: -45px">
      <b-col cols="12">
        <!-- <b-button disabled style="float: right" variant="info" class="mr-5">{{ $t("Comparison Report")
              }}</b-button>
            <b-button disabled style="float: right" variant="primary" class="mr-5">{{ $t("Subject Report")
              }}</b-button> -->
        <vue-apex-charts height="220" width="100%" :options="{
            ...lineChartModifiedOptions,
            title: {
              text: $t('Memory') + ' ' + $t('Performance'),
              offsetY: 25,
              align: 'center',
              style: {
                color: '#000',
              },
            },
          }" :series="[memoryChartData]" :key="refreshKey" />
      </b-col>
    </b-row>
    <!-- Time & Mistake Charts -->
    <!-- <b-row style="margin-top: 20px">
      <b-col cols="3" class="d-flex justify-content-start">
        <span class="data-text ml-3">{{ $t("Name") }}:</span>
        <span class="data-text ml-1">{{ userData.name }}</span>
      </b-col>
      <b-col cols="3" class="d-flex justify-content-start">
        <span class="data-text">{{ $t("Age") }}:</span>
        <span class="data-text ml-1">{{ userData.age }}</span>
      </b-col>
      <b-col cols="3" class="d-flex justify-content-end">
        <span class="data-text">{{ $t("Start") }}:</span>
        <span class="data-text ml-1">{{ new Date(userData.program.startDate).toLocaleDateString($i18n.locale)
          }}</span>
      </b-col>
      <b-col cols="3" class="d-flex justify-content-end">
        <span class="data-text">{{ $t("Progress") }}:</span>
        <span class="data-text ml-1 mr-1">({{ Math.ceil(userData.program.exercisesDone / 12) }} /
          {{ userData.program.exercisesTotal / 12 }})</span>
      </b-col>
    </b-row>
    <b-row style="margin-top: -45px">
      <b-col md="12" sm="12">
        <vue-apex-charts height="220" width="100%" :options="{
            ...smallChartOptions,
            title: {
              text: $t('Attention') + ' ' + $t('Time-Mistake Averages'),
              offsetY: 25,
              align: 'center',
              style: {
                color: '#000',
              },
            },
          }" :series="smallChartData[0].series" :key="refreshKey" />
      </b-col>
     </b-row>
    <b-row style="margin-top: -45px">
      <b-col md="12" sm="12">
        <vue-apex-charts height="220" width="100%" :options="{
            ...smallChartOptions,
            title: {
              text: $t('Logic') + ' ' + $t('Time-Mistake Averages'),
              offsetY: 25,
              align: 'center',
              style: {
                color: '#000',
              },
            },
          }" :series="smallChartData[1].series" :key="refreshKey" />
      </b-col>
    </b-row>
    <b-row style="margin-top: -45px">
      <b-col md="12" sm="12">
        <vue-apex-charts height="220" width="100%" :options="{
            ...smallChartOptions,
            title: {
              text: $t('Concentration') + ' ' + $t('Time-Mistake Averages'),
              offsetY: 25,
              align: 'center',
              style: {
                color: '#000',
              },
            },
          }" :series="smallChartData[2].series" :key="refreshKey" />
      </b-col>
    </b-row>
    <b-row style="margin-top: -45px">
      <b-col md="12" sm="12">
        <vue-apex-charts height="220" width="100%" :options="{
            ...smallChartOptions,
            title: {
              text: $t('Memory') + ' ' + $t('Time-Mistake Averages'),
              offsetY: 25,
              align: 'center',
              style: {
                color: '#000',
              },
            },
          }" :series="smallChartData[3].series" :key="refreshKey" />
      </b-col>
    </b-row> -->
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BCard,
  BSpinner,
  BButton,
  BModal,
} from "bootstrap-vue";
// import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import UserViewUserInfoCard from "./UserViewUserInfoCard.vue";
import ChartsjsLineChart from "./ChartjsLineChart.vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import VueApexCharts from "vue-apexcharts";
import userStoreModule from "./parentStoreModule";
// import UserViewUserPlanCard from "./UserViewUserPlanCard.vue";
import TestCard from "./TestCard.vue";
import UserViewUserTimelineCard from "./UserViewUserTimelineCard.vue";
import UserViewUserPermissionsCard from "./UserViewUserPermissionsCard.vue";
import { smallChartOptions } from "./smallChartOptions";
import { lineChartOptions } from "./lineChartOptions";
import axios from "@axios";
import StatisticCardWithLineChart from "@/@core/components/statistics-cards/StatisticCardWithLineChart.vue";
// import userData from "@/store/user-data";
// import {t as translate} from "@/libs/i18n";
const {
  axiosRoutes: { exercise: path },
} = require("/G_CONFIG");

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    BSpinner,
    BButton,
    BModal,
    // Local Components
    UserViewUserInfoCard,
    TestCard,
    UserViewUserTimelineCard,
    UserViewUserPermissionsCard,
    ChartsjsLineChart,
    StatisticCardVertical,
    StatisticCardWithAreaChart,
    StatisticCardWithLineChart,
    VueApexCharts,
    StatisticCardWithLineChart,
  },
  data() {
    return {
      refreshKey: 0,
      lineChartModifiedOptions: {
        ...lineChartOptions,
      },
      bigChartData: [
        {
          name: "",
          data: [],
          series: [],
        },
        { name: "", data: [], series: [] },
        { name: "", data: [], series: [] },
        {
          name: "",
          data: [],
          series: [],
        },
      ],
      // areaChartModifiedOptions: {
      //   ...areaChartOptions,
      //   ...areaChartOptionsModify,
      // },
      smallChartOptions: smallChartOptions,
      panelTitles: ["", "", "", ""],
      panelIcons: ["AlertCircleIcon", "PieChartIcon", "CrosshairIcon", "DiscIcon"],
      chartColors: ["primary", "success", "info", "warning"],
      smallChartData: [
        {
          series: [
            { name: this.$t("Attention"), data: [0] },
            { name: this.$t("Attention"), data: [0] },
          ],
        },
        {
          series: [
            { name: this.$t("Concentration"), data: [0] },
            { name: this.$t("Concentration"), data: [0] },
          ],
        },
        {
          series: [
            { name: this.$t("Logic"), data: [0] },
            { name: this.$t("Logic"), data: [0] },
          ],
        },
        {
          series: [
            { name: this.$t("Memory"), data: [0] },
            { name: this.$t("Memory"), data: [0] },
          ],
        },
      ],
      attentionChartData: {
        name: "",
        data: [],
        series: [],
      },

      concentrationChartData: {
        name: "",
        data: [],
        series: [],
      },
      logicChartData: {
        name: "",
        data: [],
        series: [],
      },
      memoryChartData: {
        name: "",
        data: [],
        series: [],
      },
      // groupChartOptions: {
      //   ...areaChartOptions,
      //   ...areaChartOptionsModify,
      //   ...groupChartOptionsModify,
      // },
    };
  },
  mounted() {
    // console.log(locale);
    // document.querySelector("#app > div").classList.add("exercise-wrapper");
    let body = document.getElementsByTagName("body")[0];
    body.classList.remove("dark-layout");
    body.style.background = "#fff";
    let app = document.getElementById("app");
    app.classList.remove("h-100");
    window.addEventListener("afterprint", function () {
      window.close();
    });
  },
  methods: {
    testReportClicked(data) {
      this.$refs.testReport.show();
    },
    numberToGrade(number) {
      const values = ["A", "B", "C", "D", "E"];
      return values[number - 1];
    },
    dataFetched(data) {
      const names = (this.panelTitles = Object.keys(data.smallChartData));
      for (let i = 0; i < names.length; i++) {
        this.smallChartData[i].series[0].data = data.smallChartData[
          names[i]
        ][0].data.map((x) => (x > 6 ? 0 : x));
        this.smallChartData[i].series[0].name = data.smallChartData[names[i]][0].label;
        this.smallChartData[i].series[1].data = data.smallChartData[
          names[i]
        ][1].data.map((x) => (x > 6 ? -1 : x));
        // this.smallChartData[i].series[1].data = data.smallChartData[names[i]][0].data.map(
        //   (x, index) => this.smallChartData[i].series[0].data[index] - x
        // );
        this.smallChartData[i].series[1].name = data.smallChartData[names[i]][1].label;
        this.panelTitles[i] = this.$t(names[i]);
      }
      console.log(this.smallChartData);
      this.renderTable(data);
      this.refreshKey++;
    },
    renderTable(data) {
      if (this.userData == undefined || this.userData.program == undefined) {
        setTimeout(() => {
          this.renderTable(data);
        }, 300);
        return;
      } else {
      }
      let {
        attention: { change: attChange },
        logic: { change: logChange },
        concentration: { change: conChange },
        memory: { change: memChange },
      } = this.userData.program.groups;
      attChange = [
        ...attChange.map((x) => 6 - x),
        6 - this.userData.program.groups.attention.group,
      ];
      logChange = [
        ...logChange.map((x) => 6 - x),
        6 - this.userData.program.groups.logic.group,
      ];
      conChange = [
        ...conChange.map((x) => 6 - x),
        6 - this.userData.program.groups.concentration.group,
      ];
      memChange = [
        ...memChange.map((x) => 6 - x),
        6 - this.userData.program.groups.memory.group,
      ];

      let attentionPerformance = data.bigChartData[0].data;
      let logicPerformance = data.bigChartData[1].data;
      let concentrationPerformance = data.bigChartData[2].data;
      let memoryPerformance = data.bigChartData[3].data;
      this.attentionChartData.data = attentionPerformance.map((val, index) => {
        if (attChange[index + 1]) return val + attChange[index + 1] / 10;
        else return val;
      });
      this.logicChartData.data = logicPerformance.map((val, index) => {
        if (logChange[index + 1]) return val + logChange[index + 1] / 10;
        else return val;
      });
      this.concentrationChartData.data = concentrationPerformance.map((val, index) => {
        if (conChange[index + 1]) return val + conChange[index + 1] / 10;
        else return val;
      });
      this.memoryChartData.data = memoryPerformance.map((val, index) => {
        if (memChange[index + 1]) return val + memChange[index + 1] / 10;
        else return val;
      });
      this.refreshKey++;
      // const divs = document.querySelectorAll("#app > div");
      // console.log(divs);
      setTimeout(() => {
        window.print();
      }, 1000);
    },
  },
  async created() {
    document.getElementById("loading-bg").style.display = "block";
    const id = window.location.pathname.substring(
      window.location.pathname.lastIndexOf("/") + 1
    );
    try {
      const { data: response } = await axios.get(`${path.stats + id}`);
      this.bigChartData = response.bigChartData;

      // this.logicChartData.data = this.logicChartData.map((val, index) => {
      //   return val + "|" + logicGroupChange[index];
      // });
      // this.concentrationChartData.data = this.concentrationChartData.map((val, index) => {
      //   return val + "|" + concentrationGroupChange[index];
      // });
      // this.memoryChartData.data = this.memoryChartData.map((val, index) => {
      //   return val + "|" + memoryGroupChange[index];
      // });
      this.dataFetched(response);
    } catch (error) {
      console.log(error);
    } finally {
      document.getElementById("loading-bg").style.display = "none";
    }
  },
  setup(data) {
    const userData = ref(null);
    const testData = ref(null);
    const userFetched = ref(false);
    const USER_APP_STORE_MODULE_NAME = "app-user";
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch("app-user/fetchUser", { id: router.currentRoute.params.id })
      .then((response) => {
        userData.value = response.data.subject;
        testData.value = response.data.testData;
        userFetched.value = true;
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });
    return {
      userData,
      testData,
      userFetched,
    };
  },
};
</script>

<style scoped>
.client-rows {
  margin-top: -20px;
}
.data-text {
  color: #000;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
}
* {
  margin: 0;
  padding: 0;
  font-family: "Swiss 721", sans-serif;
}
@page {
  size: A4;
  margin: 0;
  size: landscape;
}

@media print {
  @page {
    size: A4;
    margin: 5mm;
    size: landscape;
    pages: 1;
  }
}
.exercise-wrapper {
  height: 200mm;
  width: 297mm;
}
#app > div {
  height: 200mm;
  width: 297mm;
}
#app {
  height: 200mm;
  width: 297mm;
}
.page-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
}
/* .page {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
} */
</style>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
