var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"border-primary",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"d-flex justify-content-between align-items-center mb-0 cardheadercustom"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("Last Test Information"))+" ")]),(_vm.testData != null)?_c('b-button',{staticClass:"btn-sm",attrs:{"variant":"primary","to":{
        name: 'testreport',
        params: { id: _vm.$route.params.id, testid: _vm.testData._id },
      }}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1):_vm._e(),_c('small',{staticClass:"text-muted w-100"},[_vm._v(_vm._s(_vm.testData ? new Date(_vm.testData.createdAt).toLocaleDateString(_vm.$i18n.locale, { year: "numeric", month: "long", day: "numeric", }) : _vm.$t("Not Done")))])],1),_c('b-card-body',[_c('ul',{staticClass:"list-unstyled"},[_c('li',{},[_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t("Attention:"))+" ")]),(_vm.testData)?_c('b-badge',{attrs:{"variant":_vm.testData ? _vm.gradeColor(_vm.testData.attention) : 'dark'}},[_vm._v(" "+_vm._s(_vm.testData ? _vm.numberToGrade(_vm.testData.attention) : "")+" ")]):_c('span',[_vm._v(" - ")]),_c('span')],1),_c('li',[_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t("Logic:"))+" ")]),(_vm.testData)?_c('b-badge',{attrs:{"variant":_vm.testData ? _vm.gradeColor(_vm.testData.logic) : 'dark'}},[_vm._v(" "+_vm._s(_vm.testData ? _vm.numberToGrade(_vm.testData.logic) : "")+" ")]):_c('span',[_vm._v(" - ")]),_c('span')],1),_c('li',[_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t("Concentration:"))+" ")]),(_vm.testData)?_c('b-badge',{attrs:{"variant":_vm.testData ? _vm.gradeColor(_vm.testData.concentration) : 'dark'}},[_vm._v(" "+_vm._s(_vm.testData ? _vm.numberToGrade(_vm.testData.concentration) : "")+" ")]):_c('span',[_vm._v(" - ")]),_c('span')],1),_c('li',[_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t("Memory:"))+" ")]),(_vm.testData)?_c('b-badge',{attrs:{"variant":_vm.testData ? _vm.gradeColor(_vm.testData.memory) : 'dark'}},[_vm._v(" "+_vm._s(_vm.testData ? _vm.numberToGrade(_vm.testData.memory) : "")+" ")]):_c('span',[_vm._v(" - ")]),_c('span')],1)]),(_vm.programData.active)?_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"buttoncustom",attrs:{"variant":_vm.$can('read', ((_vm.isFirm ? 'F' : 'S') + ".Exercise")) &&
          _vm.programData.groups.attention.group &&
          _vm.programData.groups.logic.group &&
          _vm.programData.groups.concentration.group &&
          _vm.programData.groups.memory.group &&
          _vm.programData.active
            ? 'primary'
            : 'secondary',"block":"","to":{ name: 'exercises-detail', params: {} },"disabled":_vm.$can('read', ((_vm.isFirm ? 'F' : 'S') + ".Exercise")) &&
          _vm.programData.groups.attention.group &&
          _vm.programData.groups.logic.group &&
          _vm.programData.groups.concentration.group &&
          _vm.programData.groups.memory.group &&
          _vm.programData.active &&
          _vm.isActive
            ? false
            : true}},[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('feather-icon',{staticClass:"mr-1",attrs:{"size":"1.5x","icon":"AlignJustifyIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("Set Operations")))])],1)]),_c('b-button',{staticClass:"buttoncustom",attrs:{"disabled":!_vm.programData.refundable || _vm.userRefunds <= 0,"block":"","variant":_vm.programData.refundable && _vm.userRefunds > 0 ? 'info' : 'dark'},on:{"click":function($event){_vm.programData.refundable && _vm.userRefunds > 0 ? _vm.refundProgram() : ''}}},[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('feather-icon',{staticClass:"mr-1",attrs:{"size":"1.5x","icon":"RotateCcwIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("Cancel Program")))])],1)])],1):_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"buttoncustom",attrs:{"variant":_vm.$can('create', ((_vm.isFirm ? 'F' : 'S') + ".Test")) ? 'primary' : 'secondary',"disabled":(!_vm.isActive || _vm.userPrograms <= 0) &&
          _vm.$can('create', ((_vm.isFirm ? 'F' : 'S') + ".Test")),"block":""},on:{"click":function($event){_vm.$can('create', ((_vm.isFirm ? 'F' : 'S') + ".Test")) ? _vm.confirmBeforeTest() : ''}}},[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('feather-icon',{staticClass:"mr-1",attrs:{"size":"1.5x","icon":"ActivityIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("Apply GBT")))])],1)]),(_vm.$can('create', ((_vm.isFirm ? 'F' : 'S') + ".Test")) ? false : true)?_c('b-button',{staticClass:"buttoncustom",attrs:{"block":"","variant":"primary","to":{
          name: 'account-settings',
        },"disabled":!_vm.isActive}},[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('feather-icon',{staticClass:"mr-1",attrs:{"size":"1.5x","icon":"AwardIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("Approve Your Account")))])],1)]):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }